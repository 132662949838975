@if (commission) {
  @if (aggregatedExecutiveRuleCommissions && commission.custom?.isCustom) {
    <div class="card">
      <div class="card-body">
        <div class="card-title">Payouts</div>
        <div class="grid md:grid-cols-2 gap-2">
          <div class="text-left">
            <div class="text-sm text-gray-500">
              <p>Commission Amount</p>
              <p>
                <b>{{ commission.commissionAmount | currency }}</b>
              </p>
            </div>
          </div>
          <div class="text-right">
            <p>Total Custom Payouts</p>
            <p>
              <b>{{ totalCustomPayouts | currency }}</b>
              @if (
                commission.commissionAmount &&
                totalCustomPayouts &&
                commission.commissionAmount !== totalCustomPayouts
              ) {
                <span class="text-sm">
                  ({{
                    totalCustomPayouts - commission.commissionAmount | currency
                  }})
                </span>
              }
            </p>
          </div>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th>User</th>
              <th>Tier Level</th>
              <th>Payout</th>
            </tr>
          </thead>
          <tbody>
            @for (
              userId of aggregatedExecutiveRuleCommissions.executiveLadder
                | reverse;
              track userId
            ) {
              <tr>
                <td>
                  <app-user-card [userId]="userId"></app-user-card>
                </td>

                <td>
                  @if (
                    aggregatedExecutiveRuleCommissions.executiveTierLadder[
                      userId
                    ];
                    as tier
                  ) {
                    @for (level of tier.tierLevels; track level) {
                      <p>{{ level.payoutDefinition?.levelName }}</p>
                    }
                  }
                </td>
                <td>
                  <input
                    type="number"
                    [(ngModel)]="payoutsByUser[userId]"
                    (ngModelChange)="payoutsByUserChanged()"
                    (focusout)="payoutFocusOut(userId)"
                    placeholder="Enter Payout Here"
                    class="input input-bordered w-full max-w-xs"
                  />
                </td>
              </tr>
            }
          </tbody>
        </table>
        <div class="flex gap-2">
          @if (loadingSave) {
            <button class="btn btn-primary">
              <app-loading></app-loading>
              Save
            </button>
          } @else {
            <app-tooltip
              [text]="
                commission.commissionAmount === totalCustomPayouts
                  ? 'Save Payout'
                  : 'The Commission Amount must be fully assigned'
              "
            >
              <button
                class="btn btn-primary"
                [disabled]="commission.commissionAmount !== totalCustomPayouts"
                (click)="saveCustomPayouts()"
              >
                <fa-icon [icon]="faSave"></fa-icon>
                Save
              </button>
            </app-tooltip>
          }

          @if (loading) {
            <button class="btn btn-outline">
              <app-loading></app-loading>
              Delete Custom Payout
            </button>
          } @else {
            <button class="btn btn-outline" (click)="deleteCustomPayouts()">
              <fa-icon [icon]="faTrash"></fa-icon>
              Delete Custom Payout
            </button>
          }
        </div>
      </div>
    </div>
  } @else {
    @if (loading) {
      <button class="btn btn-outline">
        <app-loading></app-loading>
        Create Custom Payouts
      </button>
    } @else {
      <button class="btn btn-outline" (click)="createCustomPayouts()">
        <fa-icon [icon]="faEdit"></fa-icon>
        Create Custom Payouts
      </button>
    }
  }
}
