import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { ModalComponent } from '../../../ui-components/modal/modal.component';
import { Commission, UserType } from 'src/app/shared/models';
import { DfCardComponent } from './df-card/df-card.component';
import { LoadingComponent } from '../../../ui-components/loading/loading.component';
import {
  commissionOriginMap,
  commissionRateMap,
  commissionTypeMap,
  planTypeMap,
} from 'src/app/shared/data/commissions';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import {
  faCircle,
  faCircleCheck,
  faSave,
} from '@fortawesome/pro-regular-svg-icons';
import { FormsModule } from '@angular/forms';
import { CurrencyPipe, DatePipe, JsonPipe } from '@angular/common';
import { carrierMap } from '../../../commission-statistics/commission-statistics/carrier';
import { environment } from 'src/environments/environment';
import { CommissionsCustomPayoutEditComponent } from './commissions-custom-payout-edit/commissions-custom-payout-edit.component';
import {
  Commission_DfValue,
  Commission_IdentifiedResult,
} from 'src/app/shared/models/classes/commissions';
import { AuthService, UserInfo } from 'src/app/shared/services/auth.service';
import { CommissionService } from 'src/app/shared/services/commission.service';
import { takeWhile } from 'rxjs';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-commissions-edit-modal',
  standalone: true,
  imports: [
    CurrencyPipe,
    CommissionsCustomPayoutEditComponent,
    DatePipe,
    DfCardComponent,
    FaIconComponent,
    FormsModule,
    JsonPipe,
    LoadingComponent,
    ModalComponent,
  ],
  templateUrl: './commissions-edit-modal.component.html',
  styleUrl: './commissions-edit-modal.component.scss',
})
export class CommissionsEditModalComponent implements AfterViewInit {
  authService = inject(AuthService);
  private cdr = inject(ChangeDetectorRef);
  private commissionService = inject(CommissionService);
  private toastService = inject(ToastService);

  @Input() openWith?: {
    commission?: Commission;
    commissionId?: string;
    userId?: string;
  };

  @Output() retryClicked = new EventEmitter<Commission>();
  @Output() modalDismissedFired = new EventEmitter<boolean>();

  @ViewChild('modal') modal?: ModalComponent;

  faCircle = faCircle;
  faCircleCheck = faCircleCheck;
  faSave = faSave;

  commission?: Commission;

  carrierMap = carrierMap;
  commissionOriginMap = commissionOriginMap;
  commissionRateMap = commissionRateMap;
  commissionTypeMap = commissionTypeMap;
  planTypeMap = planTypeMap;
  Commission = Commission;
  UserType = UserType;

  loading = false;
  newResult?: Commission_IdentifiedResult;

  showAdvanced = false;

  isDemo = environment.isDemo;
  production = environment.production;

  authUser?: UserInfo;

  ngAfterViewInit() {
    if (this.openWith) {
      if (this.openWith.commission) {
        this.open(this.openWith.commission);
      } else if (this.openWith.commissionId && this.openWith.userId) {
        this.open();

        this.commissionService
          .loadSingle(this.openWith.userId, this.openWith.commissionId)
          .then(commission => {
            console.log('loaded commission', commission);
            if (commission) {
              this.commission = commission;
              this.cdr.detectChanges();
            }
          });
      }
      this.cdr.detectChanges();
    }
  }

  open(commission?: Commission) {
    if (commission) {
      this.commission = commission;
    }

    this.modal?.show();
  }

  retry(commission: Commission) {
    console.log('retry clicked', commission);
    this.retryClicked.emit(commission);
  }

  toggleShowAdvanced() {
    this.showAdvanced = !this.showAdvanced;
    this.cdr.detectChanges();
  }

  modalDismissed() {
    delete this.commission;
    this.modalDismissedFired.emit();
  }

  isChecked(commission: Commission, result: Commission_IdentifiedResult) {
    const identifiedResult = commission.identifiedResult;
    return identifiedResult
      ? this.compareResults(identifiedResult, result)
      : false;
  }

  compareResults(
    result1: Commission_IdentifiedResult,
    result2: Commission_IdentifiedResult
  ) {
    return (
      result1.isNegative === result2.isNegative &&
      result1.monthCountChargeback === result2.monthCountChargeback &&
      result1.operationMessage === result2.operationMessage &&
      result1.operationType === result2.operationType &&
      result1.pennyTolerance === result2.pennyTolerance &&
      this.compareDf(result1.df1, result2.df1) &&
      this.compareDf(result1.df2, result2.df2)
    );
  }

  compareDf(df1?: Commission_DfValue, df2?: Commission_DfValue) {
    return (
      (!df1 && !df2) ||
      (df1?.column === df2?.column &&
        df1?.columnDetail?.level === df2?.columnDetail?.level &&
        df1?.columnDetail?.planType === df2?.columnDetail?.planType &&
        df1?.columnDetail?.rate === df2?.columnDetail?.rate &&
        df1?.columnDetail?.state === df2?.columnDetail?.state &&
        df1?.columnDetail?.value === df2?.columnDetail?.value &&
        df1?.row === df2?.row &&
        df1?.value === df2?.value)
    );
  }

  selectPossibleIdentifiedResult(result: Commission_IdentifiedResult) {
    if (this.newResult && this.compareResults(this.newResult, result)) {
      this.newResult = this.commission?.identifiedResult;
    } else {
      this.newResult = result;
    }
    this.cdr.detectChanges();
  }

  saveIdentifiedResult() {
    this.loading = true;
    this.authService.currentUser$
      .pipe(takeWhile(u => !u.id, true))
      .subscribe(user => {
        if (user.id) {
          if (!this.commission || !this.newResult) {
            return;
          }
          this.commissionService
            .updateIdentifiedResult(user.id, this.commission, this.newResult)
            .then(res => {
              console.log('saved identified result', res);
              this.loading = false;
            })
            .catch(err => {
              this.loading = false;
              this.toastService.error('An error occurred', 'Please try again');
            });
        }
      });
  }
}
